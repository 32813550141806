import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckboxOutline, IoIosArrowRoundForward } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Чистка BioClean
			</title>
			<meta name={"description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:title"} content={"Чистка BioClean"} />
			<meta property={"og:description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/unique-handdrawn-floral-modern-shapes.png?v=2023-04-27T21:48:23.623Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1563453392212-326f5e854473?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Чистка BioClean
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Почувствуйте непревзойденную чистоту с помощью Чистка BioClean, где нашим приоритетом является сделать ваше пространство нетронутым. Мы верим, что чистая окружающая среда имеет важное значение для продуктивной и здоровой жизни, поэтому мы посвящаем себя предоставлению первоклассных услуг по уборке, удовлетворяющих всем вашим потребностям.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					О нас
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					В Чистка BioClean мы специализируемся на превращении жилых и коммерческих помещений в безупречные гавани. Наша команда профессиональных уборщиков научена справиться с любой задачей уборки с точностью и заботливостью. Используя экологически чистые продукты и передовые технологии, мы обеспечиваем тщательную уборку, которая способствует здоровью и экологичности.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Надежные профессионалы
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Каждый член нашей команды проходит тщательную проверку и обучение по самым высоким стандартам.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Узнать больше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Экологически чистая уборка
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Мы используем экологически чистые моющие средства, безопасные для вас и планеты.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Узнать больше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Гибкий график
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Мы предлагаем удобные варианты планировки в соответствии с вашим занятым образом жизни.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Узнать больше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 20px 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Стремление к совершенству
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="normal 400 25px/1.5 --fontFamily-serifGaramond" color="#333b41">
							Мы постоянно стремимся превзойти ожидания, сосредотачиваясь на тщательном внимании к деталям и удовлетворении клиентов. Наши услуги адаптированы к конкретным требованиям вашего помещения, обеспечивая персонализированный опыт уборки каждый раз.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1626379481874-3dc5678fa8ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});